import React, { memo } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card";
import { useEffect, useRef, useState } from "react";
import { addIntegration, deleteIntegration, getConnectedDrive, updateIntergrationName } from "../../../shared/services/userSetting";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { Button } from "../../../core/components/ui/button";
import { FolderSync, PencilIcon, Plus, SaveIcon, Trash2, X } from "lucide-react";
import IntegrationListViewer from "./integrationList";
import { connectedIntegration } from "../../../shared/models/integration-model";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CaretSortIcon, DotsHorizontalIcon } from "@radix-ui/react-icons"
import { ColumnDef } from "@tanstack/react-table"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../core/components/ui/dropdown-menu"
import { FolderSyncDialog } from "../../../shared/components/folder-sync/folder-sync";
import { DataTable } from "../../../shared/components/data-table/data-table";
import { FolderSyncStatusDialog } from "../../../shared/components/folder-sync/folder-sync-status";
import { resetNewIntegration, resetSuccess, resetSyncList, toggleIntergrationEditable } from "../../../redux-store/reducers/userSetting-reducers";
import { Input } from "../../../core/components/ui/input";
import AddApiKeyIntegration from '../../../shared/components/add-api-keys-integration/add-api-keys-integration';
import IntegrationConfig from './integrationConfig';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../core/components/ui/tabs';

export const Integrations = () => {

  const [open, setOpen] = useState(false);
  const [openSync, setOpenSync] = useState(false);
  const [openSyncStatus, setOpenSyncStatus] = useState(false);
  const [openSyncData, setOpenSyncData] = useState(false);
  const [openApiKey, setOpenApiKey] = useState(false);

  const dispatch: AppDispatch = useDispatch()
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { integrationList, newIntegration, success, openApiKeyDialog } = useSelector((state: RootState) => state.userSetting);

  const getConnectedDriveCalled = useRef(false);
  const addIntegrationCalled = useRef(false);
  const [selectedTab, setSelectedTab] = useState('list');

  useEffect(() => {
    if (!getConnectedDriveCalled.current) {
      dispatch(getConnectedDrive());
      // dispatch(getAllSyncStatus(null))
      getConnectedDriveCalled.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(getConnectedDrive());
      dispatch(resetSuccess(false))
    }// eslint-disable-next-line 
  }, [success])


  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const integrationName = pathParts[pathParts.indexOf('integrations') + 1];

    const code = searchParams.get('code');

    if (code && !addIntegrationCalled.current) {
      const addRequest = {
        name: '',
        pluginId: integrationName,
        oathCode: code
      };
      if (addRequest.pluginId) {
        dispatch(addIntegration(addRequest))
        addIntegrationCalled.current = true;
      }
      navigate('/profile/integrations');
    }
    // eslint-disable-next-line 
  }, [location, searchParams, dispatch, navigate]);

  useEffect(() => {
    if (newIntegration) {
      handleSyncFolder(newIntegration);
    }// eslint-disable-next-line 
  }, [newIntegration]);

  useEffect(() => {
    if (!open && newIntegration) {
      dispatch(resetNewIntegration())
    }// eslint-disable-next-line 
  }, [open, newIntegration])


  useEffect(() => {
    if (openApiKeyDialog) {
      setOpenApiKey(openApiKeyDialog)
    }
  }, [openApiKeyDialog])

  const handleRemoveFile = (integrationId: number) => {
    dispatch(deleteIntegration(integrationId));
  };

  const handleDialog = () => {
    setOpen(!open)
  }

  const handleSyncFolder = (value: any) => {
    setOpenSyncData(value)
    setOpenSync(!openSync)
  }


  const handleSyncStatusFolder = (value: any) => {
    setOpenSyncData(value)
    setOpenSyncStatus(!openSync)
    dispatch(resetSyncList())
  }

  // const handleSyncAll = () => {
  //   navigate('/profile/integrations/sync-progress');
  // }

  const toggleEditMode = (integrationId: number) => {
    dispatch(toggleIntergrationEditable(integrationId))
  };

  const saveEdit = (integrationId: number, data: string) => {
    dispatch(updateIntergrationName({ processId: integrationId, name: data }))
    toggleEditMode(integrationId);
  };

  const cancelEdit = (integrationId: number) => {
    toggleEditMode(integrationId);
  };

  const handleMainTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <Tabs onValueChange={handleMainTabChange} defaultValue="list">
          <TabsList className="bg-gray-100 p-2 rounded-lg flex space-x-2 justify-start w-full">
            <TabsTrigger
                value="list"
                className="px-6 py-1 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
            >
                List
            </TabsTrigger>
            <TabsTrigger
                value="config"
                className="px-6 py-1 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
            >
                Configurations
            </TabsTrigger>
        </TabsList>
    <TabsContent value="list" className="mt-4">
    <div className="flex flex-row w-full overflow-auto gap-6 p-6 h-100vh">
      <Card className="w-full h-fit">
        <CardHeader>
          <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
            <p className="text-xl font-bold mb-2">Integrations</p>
            <Button size={"lg"} type="submit" onClick={handleDialog} className="space-x-2">
              <Plus className="size-5" /> <p className="hidden md:flex">Add New Integration</p>
            </Button>

          </CardTitle>
          <CardDescription>
            <span className="flex flex-row w-full items-center justify-between">
              <span className="">
                Connected Integrations
              </span>
              {/* {syncingList && syncingList.length > 0 &&
                <span className="text-blue-600 capitalize hover:underline hover:cursor-pointer" onClick={() => handleSyncAll()}>
                  syncing list : {syncingList.length}
                </span>
              } */}
            </span>
          </CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col justify-center items-center">
          <DataTable
            data={integrationList}
            getColumns={() => getColumns(handleRemoveFile, handleSyncFolder, handleSyncStatusFolder, toggleEditMode, saveEdit, cancelEdit)}
          />
        </CardContent>
      </Card>
      {open && <IntegrationListViewer open={open} setOpen={setOpen} />}
      {openSync && <FolderSyncDialog open={openSync} setOpen={(value: any) => setOpenSync(value)} data={openSyncData} />}
      {openSyncStatus && <FolderSyncStatusDialog open={openSyncStatus} setOpen={(value: any) => setOpenSyncStatus(value)} data={openSyncData} />}
      {openApiKey && <AddApiKeyIntegration open={openApiKey} setOpen={(value) => setOpenApiKey(value)} />}
    </div >
    </TabsContent>
    <TabsContent value="config" className="mt-4">
      <IntegrationConfig/>
    </TabsContent>
    </Tabs>
  );
}

interface IntegrationNameCellProps {
  id: number;
  name: string;
  editable: boolean;
  saveEdit: (value: string) => void;
  cancelEdit: () => void;
}

const IntegrationNameCell = memo(({ id, name, editable, saveEdit, cancelEdit }: IntegrationNameCellProps) => {
  const [localName, setLocalName] = useState(name);

  const inputRef = useRef<HTMLInputElement>(null);
  const [cursorPositionRef, setCursorPositionRef] = useState<number>();


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCursorPositionRef(e?.target.selectionStart as number);
    setLocalName(e.target.value);
    // onChange(id, e.target.value); // Update global state if needed
  };


  useEffect(() => {
    if (inputRef.current && editable && cursorPositionRef) {
      inputRef.current.setSelectionRange(cursorPositionRef ?? 0, cursorPositionRef ?? 0);
    }
  }, [localName, editable, cursorPositionRef]);

  return (
    <div className="lowercase flex ml-10 space-x-2">
      {!editable ? (
        name
      ) : (
        <Input autoFocus
          ref={inputRef}
          value={localName}
          onChange={handleInputChange}
        />
      )}
      {editable && (
        <div className="flex flex-row space-x-2">
          <Button size={"icon"} variant="outline" className="border-secondary rounded-full" onClick={() => saveEdit(localName)}>
            <SaveIcon className="size-5" />
          </Button>
          <Button size={"icon"} variant="outline" className="border-secondary rounded-full" onClick={cancelEdit}>
            <X className="size-5" />
          </Button>
        </div>
      )}
    </div>
  );
});



export const getColumns =
  (
    handleRemoveFile: (integrationId: number) => void,
    handleSyncFolder: (value: any) => void,
    handleSyncStatusFolder: (value: any) => void,
    toggleEditMode: (value: any) => void,
    saveEdit: (value: any, data: string) => void,
    cancelEdit: (value: any) => void,
  ): ColumnDef<connectedIntegration, any>[] => [
      // {
      //   id: "select",
      //   header: ({ table }) => (
      //     <Checkbox
      //       checked={
      //         table.getIsAllPageRowsSelected() ||
      //         (table.getIsSomePageRowsSelected() && "indeterminate")
      //       }
      //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      //       aria-label="Select all"
      //     />
      //   ),
      //   cell: ({ row }) => (
      //     <Checkbox
      //       checked={row.getIsSelected()}
      //       onCheckedChange={(value) => row.toggleSelected(!!value)}
      //       aria-label="Select row"
      //     />
      //   ),
      //   enableSorting: false,
      //   enableHiding: false,
      // },
      {
        accessorKey: "icon",
        header: "Icon",
        cell: memo(({ row }) => (
          <div className="w-10 h-10">
            <img src={row.getValue('icon')} alt="integration icon" />
          </div>
        )),
      },
      {
        accessorKey: "pluginId",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Integration
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <div className="lowercase flex ml-10">{row.getValue("pluginId")}</div>
        )),
      },
      {
        accessorKey: "name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Integration name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: memo(({ row }) => (
          <IntegrationNameCell
            id={row.original.id}
            name={row.getValue("name")}
            editable={row.original.editable}
            saveEdit={(value: string) => saveEdit(row.original.id, value)}
            cancelEdit={() => cancelEdit(row.original.id)} />

        )),
      },
      {
        id: "actions",
        enableHiding: false,
        cell: memo(({ row }) => {
          const integration = row.original;
          return (
            <div className="flex flex-row items-center justify-end space-x-2">
              {!row.original.editable && (
                <Button size={"icon"} variant="outline" className="border-secondary rounded-full" onClick={() => toggleEditMode(row.original.id)}>
                  <PencilIcon className="size-5" />
                </Button>
              )}
              {/* <Button size={"icon"} variant="outline" className="border-secondary rounded-full" onClick={() => handleSyncStatusFolder(integration)}>
                <MonitorCog className="size-5" />
              </Button> */}
              <Button size={"icon"} className="rounded-full" onClick={() => handleSyncFolder(integration)}>
                <FolderSync className="size-5" />
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <DotsHorizontalIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={() => handleRemoveFile(integration.id)}>
                    <Trash2 className="mr-2" />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        }),
      },
    ]


