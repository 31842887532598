import { Ellipsis, Trash2, Edit2 } from "lucide-react";
import { MouseEvent, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../core/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../core/components/ui/dropdown-menu";
import { resetSelectedChat } from "../../../redux-store/reducers/chat-reducers";
import { AppDispatch } from "../../../redux-store/stores/store";
import { ChatService } from "../../../shared/services/chat/chat-service";
import { title } from "process";

export const Titles = (props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(props.chatDetails.name);
    const [menuOpen, setMenuOpen] = useState(false);

    const chatServiceInstatnce = useMemo(() => new ChatService(), []);

    const handleDeleteChat = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(chatServiceInstatnce.DeleteChat(props));
        if (params && parseInt(params['chat-id'] as string) === props.id) {
            dispatch(resetSelectedChat());
            navigate('/home');
        }
    };

    const handleEditChat = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setIsEditing(true);
        setMenuOpen(false);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setNewName(e.target.value);
        console.log(newName);
    };

    const handleNameSubmit = () => {
        if (newName.trim() !== "") {
            const titleReq = {
                id:props.id,
                title:newName
            }

            dispatch(chatServiceInstatnce.updateTitle(titleReq))
            setIsEditing(false);
        }
    };

    const commandList = [
        { code: "file-only", value: "File only" },
        { code: "directory", value: "Directory" },
        { code: "web-only", value: "Web only" },
        { code: "web-also", value: "Web also" },
        { code: "local-only", value: "Local only" },
        { code: "fo", value: "File only" },
        { code: "d", value: "Directory" },
        { code: "wo", value: "Web only" },
        { code: "wa", value: "Web also" },
        { code: "lo", value: "Local only" },
    ];

    const replaceTextWithDiv = (value: any): any => {
        const firstWord = value.split(" ")[0];
        const isContain = commandList.filter(item => `/${item.code.toLowerCase()}` === firstWord.toLowerCase());
        if (isContain.length > 0) {
            const newValue = (
                <>
                    {value.slice(firstWord.length)}
                </>
            );
            return newValue;
        }
        return value;
    };

    return (
        <>
            {isEditing ? (
                <div className="flex flex-row w-full p-2 rounded-md h-[36px]"> {/* Render div instead of NavLink when editing */}
                    <div className="flex flex-row items-center justify-between w-full h-full text-sm">
                        <div className="w-11/12 line-clamp-1 capitalize">
                            <input
                                type="text"
                                value={newName}
                                onChange={handleNameChange}
                                onBlur={handleNameSubmit}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') handleNameSubmit();
                                }}
                                className="border p-1 rounded"
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <NavLink to={props.to} className={({ isActive }) => isActive ? "flex flex-row w-full hover:bg-muted-foreground/20 p-2 rounded-md bg-primary/10 font-semibold h-[36px]" : "flex flex-row w-full hover:bg-muted-foreground/20 p-2 rounded-md h-[36px]"}>
                    <div className="flex flex-row items-center justify-between w-full h-full text-sm">
                        <div className="w-11/12 line-clamp-1 capitalize">
                            {replaceTextWithDiv(props.chatDetails.name)}
                        </div>
                        <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}> {/* Control menu open state */}
                            <DropdownMenuTrigger asChild className="">
                                <Button variant="ghost" className="size-5 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <Ellipsis className="size-3.5" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="start">
                                <DropdownMenuItem className="space-x-2" onClick={handleEditChat}>
                                    <Edit2 className="size-5" /> <p>Rename</p>
                                </DropdownMenuItem>
                                <DropdownMenuItem className="text-red space-x-2" onClick={handleDeleteChat}>
                                    <Trash2 className="size-5" /> <p>Delete</p>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </NavLink>
            )}
        </>
    );
};
