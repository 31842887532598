import { createSlice } from '@reduxjs/toolkit'
import { CategoryModel } from '../../shared/models/categoryConfig-model';
import { deleteCategoryConfig, getCategoryConfig, saveCategoryConfig } from '../../shared/services/categoryConfig';
import { title } from 'process';


const initialState: CategoryModel = {
    categories : [],
    loading: false,
    error: '',
    success: false,
    resetSuccess: false,
}


const integrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategoryConfig.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getCategoryConfig.fulfilled, (state, { payload }) => {
                state.categories = payload.res
                state.loading = false;
                state.success = true;
            })
            .addCase(getCategoryConfig.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(saveCategoryConfig.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(saveCategoryConfig.fulfilled, (state, { payload }) => {
                const exists = state.categories.some(cat => cat.id === payload.res.id);

                if (!exists) {
                    state.categories.push(payload.res);
                }else{
                    state.categories = state.categories.map((cat) =>
                        cat.id === payload.res.id
                            ? {
                                ...cat,
                                id: payload.res.id,
                                folders:payload.res.folders,
                                icon: payload.res.icon,
                                title:payload.res.title
                            }
                            : cat
                    );
                }
                state.loading = false;
                state.success = true;
            })
            .addCase(saveCategoryConfig.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(deleteCategoryConfig.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(deleteCategoryConfig.fulfilled, (state, { payload }) => {
                state.categories = state.categories.filter(cat => cat.id !== payload.res);
                state.loading = false;
                state.success = true;
            })
            .addCase(deleteCategoryConfig.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            

    }
})


export const { resetState,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer