import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "..";
import { IChatService, INotificationTypes } from "../chat";
import { getConfig } from "../config-handler";
import { FirebaseService, Operators, OrderBy, Where } from '../firebase/firebase-service'

export class ChatService implements IChatService {

    newMessage = (userId: number, chatId: number, clientWindowName: 'FileUpload' | 'QnA' = 'QnA', callback: (arg0: any) => void) => {
        const queryArray: Where[] = [
            {
                id: 'ClientWindowName',
                operator: Operators['=='],
                value: clientWindowName,
            },
            {
                id: 'NotificationType',
                operator: Operators['=='],
                value: INotificationTypes.progressbar,
            },
            {
                id: 'IsViewed',
                operator: Operators['=='],
                value: false,
            },
            {
                id: 'ChatboxId',
                operator: Operators['=='],
                value: chatId,
            },
        ];

        const order: OrderBy[] = [
            {
                value: 'CreatedAt',
                order: 'desc',
            },
        ];

        const _config = getConfig()

        const path = `${_config?.REACT_APP_ENV}/User/${userId}/Notifications`
        return FirebaseService.listenToCollection(path, queryArray, order, (resp: any[]) => {
            if (resp.length > 0) {
                callback(resp)
            }
        })

    }


    getSyncStatus = (userId: number, chatId: number, clientWindowName: 'FileUpload' | 'QnA' | 'SyncWindow' = 'QnA', callback: (arg0: any) => void) => {
        const queryArray: Where[] = [
            {
                id: 'ClientWindowName',
                operator: Operators['=='],
                value: clientWindowName,
            },
            {
                id: 'NotificationType',
                operator: Operators['=='],
                value: INotificationTypes.progressbar,
            },
            {
                id: 'IsViewed',
                operator: Operators['=='],
                value: false,
            },
            {
                id: 'SyncId',
                operator: Operators['=='],
                value: chatId,
            },
        ];

        const order: OrderBy[] = [
            {
                value: 'CreatedAt',
                order: 'desc',
            },
        ];

        const _config = getConfig()
        const path = `${_config?.REACT_APP_ENV}/User/${userId}/Notifications`
        return FirebaseService.listenToCollection(path, queryArray, order, (resp: any[]) => {
            if (resp.length > 0) {
                callback(resp)
            }
        })

    }

    updateViewedMessage = async (path: string, data: any) => {
        await FirebaseService.updateData(path, data)
    }

    SendMessage = createAsyncThunk(
        '/api/FileBoxes/send-message',
        async (request: any, { rejectWithValue }) => {
            const api = axiosInstance
            try {
                const { data } = await api.post(
                    `Chatbox/question`,
                    request
                )
                return data
            } catch (error: any) {
                // return custom error message from API if any
                if (error.response && error.response.data.message) {
                    return rejectWithValue(error.response.data.message)
                } else {
                    return rejectWithValue(error.message)
                }
            }
        }
    )

    DeleteMessage = createAsyncThunk(
        '/api/FileBoxes/delete-messages',
        async (request: any, { rejectWithValue }) => {
            const api = axiosInstance
            try {
                const { data } = await api.get(
                    `ChatBox/messages/${request.id}`
                )
                return data
            } catch (error: any) {
                // return custom error message from API if any
                if (error.response && error.response.data.message) {
                    return rejectWithValue(error.response.data.message)
                } else {
                    return rejectWithValue(error.message)
                }
            }
        }
    )

    DeleteChat = createAsyncThunk(
        '/api/FileBoxes/delete-chat',
        async (request: any, { rejectWithValue }) => {
            const api = axiosInstance
            try {
                await api.delete(
                    `ChatBox/${request.id}`
                )
                return request
            } catch (error: any) {
                // return custom error message from API if any
                if (error.response && error.response.data.message) {
                    return rejectWithValue(error.response.data.message)
                } else {
                    return rejectWithValue(error.message)
                }
            }
        }
    )

    GetMessages = createAsyncThunk(
        '/api/FileBoxes/get-messages',
        async (request: any, { rejectWithValue }) => {
            const api = axiosInstance
            try {
                const { data } = await api.get(
                    `ChatBox/messages/${request.id}`
                )
                return data
            } catch (error: any) {
                // return custom error message from API if any
                if (error.response && error.response.data.message) {
                    return rejectWithValue(error.response.data.message)
                } else {
                    return rejectWithValue(error.message)
                }
            }
        }
    )




    GetChatList = createAsyncThunk(
        '/api/FileBoxes/get-chat-list',
        async (request: any, { rejectWithValue }) => {
            const api = axiosInstance
            try {
                const { data } = await api.get(
                    `chatbox/chats`
                )
                return data
            } catch (error: any) {
                // return custom error message from API if any
                if (error.response && error.response.data.message) {
                    return rejectWithValue(error.response.data.message)
                } else {
                    return rejectWithValue(error.message)
                }
            }
        }
    )


    updateTitle = createAsyncThunk(
        '/api/chatBox/update-title',
        async (request: any, { rejectWithValue }) => {
            const api = axiosInstance
            try {
                const { data } = await api.put(
                    `chatbox/update-title`,request
                )
                return data
            } catch (error: any) {
                // return custom error message from API if any
                if (error.response && error.response.data.message) {
                    return rejectWithValue(error.response.data.message)
                } else {
                    return rejectWithValue(error.message)
                }
            }
        }
    )


}